import { isStorageItemExpired, saveToStorageWithExpiration } from "./library/local-storage";

const CLOSE_REGISTRATION_BANNER_ELEMENT_ID = 'close-registration-banner';
const CLOSE_REGISTRATION_BANNER_STORAGE_KEY = 'hideRegistrationBanner';
const REGISTRATION_BANNER_ELEMENT_ID = 'registration-banner';
const REGISTRATION_BANNER_HIDDEN_CLASS = 'registration-banner--hidden'
const REGISTRATION_BANNER_OPEN_CLASS = 'registration-banner--open'

const REGISTRATION_BANNER_EXPIRATION_DAYS = 1;
const MILISECONDS_IN_ONE_DAY = 86400000;

const getRegistrationBannerExpiration = () => REGISTRATION_BANNER_EXPIRATION_DAYS * MILISECONDS_IN_ONE_DAY;

const handleCloseRegistrationBanner = () => {
    const bannerElement = document.getElementById(REGISTRATION_BANNER_ELEMENT_ID);

    const bannerExpiration = getRegistrationBannerExpiration();
    saveToStorageWithExpiration(CLOSE_REGISTRATION_BANNER_STORAGE_KEY, true, bannerExpiration);

    if (bannerElement) {
        bannerElement.classList.remove(REGISTRATION_BANNER_OPEN_CLASS);
        bannerElement.classList.add(REGISTRATION_BANNER_HIDDEN_CLASS);
    }
}

export const initRegistrationBanner = () => {
    const shouldOpen = isStorageItemExpired(CLOSE_REGISTRATION_BANNER_STORAGE_KEY);
    const bannerElement = document.getElementById(REGISTRATION_BANNER_ELEMENT_ID);

    if (shouldOpen && bannerElement) {
        bannerElement.classList.add(REGISTRATION_BANNER_OPEN_CLASS);
    }

    const closeRegistrationBannerElement = document.getElementById(CLOSE_REGISTRATION_BANNER_ELEMENT_ID);
    
    if (closeRegistrationBannerElement) {
        closeRegistrationBannerElement.addEventListener('click', handleCloseRegistrationBanner);
    } else {
        throw new Error('Cannot register hide banner listener!');
    }
}

initRegistrationBanner();
