export const saveToStorage = (key, item) => {
    localStorage.setItem(key, JSON.stringify(item));
};

export const loadFromStorage = (key) => {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const removeFromStorage = (key) => {
    localStorage.removeItem(key);
};

export const saveToStorageWithExpiration = (key, item, expiration) => {
    const timestamp = Date.now();

    const data = { data: item, metadata: { t: timestamp, e: expiration } };
    saveToStorage(key, data);
};

export const loadFromStorageWithExpiration = (key) => {
    const data = loadFromStorage(key);

    return !!data && !!data.data ? data.data : null;
};

export const isStorageItemExpired = (key) => {
    const item = loadFromStorage(key);

    const isExpirableItem = item && item.metadata && item.metadata.t && item.metadata.e;
    if (!isExpirableItem) {
        return true;
    }

    return Date.now() - item.metadata.t > item.metadata.e;
};
